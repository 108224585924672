import React from "react"
import Reveal from "react-reveal/Reveal"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PreRegister from "../components/Preregsiter"

const IntegrationPage = ({ data }) => {
  const { title, path } = data.markdownRemark.frontmatter
  const { html, excerpt } = data.markdownRemark
  const { urls } = data.site.siteMetadata

  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO title={`${title} | Integrations`} description={excerpt} />
      <section className="breadcrumb_area">
        <img
          className="breadcrumb_shap"
          src={require("../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                {title}
              </h1>
            </Reveal>
          </div>
        </div>
      </section>
      <section className="blog_area sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 blog_sidebar_left">
              <div className="blog_single mb_50">
                <div className="blog_content">
                  <Link to={path}>
                    <h5 className="f_p f_size_20 f_500 t_color mb-30">
                      {title}
                    </h5>
                  </Link>
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            <a
              href={urls.register}
              className="seo_btn seo_btn_one btn_hover mr-2"
            >
              Get started
            </a>
            <Link to={`${path}/cli`} className="seo_btn seo_btn_two btn_hover">
              Learn about the CLI
            </Link>
          </div>
        </div>
      </section>

      <PreRegister title={`Get started with ${title} webhooks`} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      excerpt
      frontmatter {
        title
        path
        image
        day: date(formatString: "DD")
        month: date(formatString: "MMM")
      }
      html
    }

    site {
      siteMetadata {
        urls {
          register
        }
      }
    }
  }
`

export default IntegrationPage
